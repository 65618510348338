import { mockSchedulingLinkDto, mockSchedulingLinkDtos } from "../utils/dev";
import { Override } from "../types";
import { dateToStr } from "../utils/dates";
import { DayOfWeek } from "./Calendars";
import { ConferenceType } from "./OneOnOnes";
import { TransformDomain } from "./types";
import { ThinPerson } from "./Users";

export type SchedulingLinkIconType =
  | "LIGHT"
  | "COFFEE"
  | "BOLT"
  | "COMMENT"
  | "STAR"
  | "AIRPLANE"
  | "TWINKLE"
  | "COMPASS"
  | "SUN"
  | "SMILE";

export type SchedulingLinkDto = {
  id: string;
  title: string;
  description: string;
  enabled: boolean;
  organizer: ThinPerson;
  timePolicy: "WORK" | "PERSONAL" | "MEETING" | null;
  daysActive: DayOfWeek[] | null;
  windowStart: string | null;
  windowEnd: string | null;
  durationMin: number;
  durationMax: number;
  daysIntoFuture: number | null;
  startDate: string | null;
  endDate: string | null;
  priority: "DEFAULT" | "HIGH";
  conferenceType: ConferenceType | null;
  iconType: SchedulingLinkIconType;
};

export type SchedulingLink = Override<
  SchedulingLinkDto,
  {
    readonly id: string;
    readonly startDate: Date | undefined;
    readonly endDate: Date | undefined;
  }
>;

export const dtoToSchedulingLink = (dto: SchedulingLinkDto): SchedulingLink => ({
  ...dto,
  startDate: dto.startDate ? new Date(dto.startDate) : undefined,
  endDate: dto.endDate ? new Date(dto.endDate) : undefined,
});
export const schedulingLinkToDto = (link: SchedulingLink): SchedulingLinkDto => ({
  ...link,
  startDate: dateToStr(link.startDate) || null,
  endDate: dateToStr(link.endDate) || null,
});

export class SchedulingLinksDomain extends TransformDomain<SchedulingLink, SchedulingLinkDto> {
  resource = "SchedulingLink";
  cacheKey = "scheduling_links";
  pk = "id";

  public serialize = schedulingLinkToDto;
  public deserialize = dtoToSchedulingLink;

  list = this.deserializeResponse(async () => {
    return new Promise<SchedulingLinkDto[]>((resolve) => {
      setTimeout(() => resolve(mockSchedulingLinkDtos()), 2000);
    });
  });

  get = this.deserializeResponse((id: string): Promise<SchedulingLinkDto> => {
    const mock = mockSchedulingLinkDto();
    mock.id = id;
    return new Promise<SchedulingLinkDto>((resolve) => {
      setTimeout(() => resolve(mockSchedulingLinkDto()), 2000);
    });
  });
}
